<template>
  <div>
    <div class="d-flex justify-content-between mb-1">
      <div>
        <router-link to="/mobile-tablet/create-part">
          <b-button variant="primary">
            <span class="align-middle">Add New Part</span>
          </b-button>
        </router-link>
      </div>
      <!-- <div>
        <router-link to="/mobile-tablet/deleted-parts">
          <b-button
            variant="danger"
            size="md"
          >
            <span class="align-middle">Show deleted Parts</span>
          </b-button>
        </router-link>
      </div> -->
    </div>
    <b-overlay
      :show="loader"
      rounded="sm"
      variant="dark"
      :opacity="0.1"
    >
      <b-card title="Parts">
        <b-row
          class="d-flex justify-content-between"
        >
          <b-col
            md="4"
            sm="8 ml-1"
            class="my-1 mx-0 px-0  d-flex justify-content-left"
          >
            <b-form-group
              label="Sort"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="md"
              label-for="sortBySelect"
              class="mb-0t"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                  class="w-50"
                >
                  <template v-slot:first>
                    <option value="">
                      none
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                  class="w-50"
                >
                  <option :value="false">
                    Asc
                  </option>
                  <option :value="true">
                    Desc
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            class="my-1 mx-0 px-0  d-flex justify-content-end "
            sm="4"
          >
            <b-form-group
              label="Filter"
              label-cols-sm="3"
              label-align-sm="left"
              label-size="md"
              label-for="filterInput"
              class="mb-0t mr-1"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-table
          :responsive="true"
          :busy.sync="isBusy"
          :items="items"
          :fields="fields"
          sort-icon-left
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-direction="sortDirection"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="text-center text-success my-2">
              <b-spinner class="align-middle" />
              <strong> Loading...</strong>
            </div>
          </template>
          <!-- Detail Start -->
          <template #cell(show_details)="row">
            <b-form-checkbox
              v-model="row.detailsShowing"
              plain
              class="vs-checkbox-con"
              @change="row.toggleDetails"
            >
              <span class="vs-checkbox">
                <span class="vs-checkbox--check">
                  <i class="vs-icon feather icon-check" />
                </span>
              </span>
              <span class="vs-label">{{ row.detailsShowing ? 'Hide' : 'Show' }}</span>
            </b-form-checkbox>
          </template>
          <template #row-details="row">
            <div>
              <b-row>
                <b-col
                  sm="12"
                  lg="4"
                  class="mb-1"
                >
                  <strong>Is Upsale : </strong>{{ row.item.is_upsale == 1 ? 'Yes': 'No' }}
                </b-col>
                <b-col
                  sm="12"
                  lg="4"
                  class="mb-1"
                >
                  <template v-if="row.item.is_upsale == 1">
                    <strong>Upsale Description : </strong>{{ row.item.upsale_description }}
                  </template>
                  <template v-else>
                    <strong>Upsale Description : </strong>N/A
                  </template>
                </b-col>
                <b-col
                  sm="12"
                  lg="4"
                  class="mb-1"
                >
                  <strong>Popup Description : </strong>{{ row.item.popup_information === null ? 'N/A' : row.item.popup_information }}
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  sm="12"
                  lg="4"
                  class="mb-1"
                >
                  <strong>Order Time : </strong>{{ row.item.order_time }} days
                </b-col>
                <b-col
                  sm="12"
                  lg="4"
                  class="mb-1"
                >
                  <strong>Is Color Required : </strong>{{ row.item.required_color == 1 ? 'Yes' : 'No' }}
                </b-col>
                <b-col
                  sm="12"
                  lg="4"
                  class="mb-1"
                >
                  <strong>Priority : </strong>{{ row.item.priority }}
                </b-col>
                <b-col
                  sm="12"
                  lg="4"
                  class="mb-1"
                >
                  <strong>Only Upsale : </strong>{{ row.item.only_upsale == 1 ? 'Yes': 'No' }}
                </b-col>
              </b-row>
              <b-row v-if="row.item.colors_for_parts">
                <b-col
                  cols="12"
                  class="mb-1"
                >
                  <strong>Colors for this part: </strong>
                </b-col>
                <b-col
                  v-for="(colors, index) in row.item.colors_for_parts"
                  :key="index"
                  sm="12"
                  lg="4"
                  class="mb-1"
                >
                  <strong>{{ colors.name }}: </strong> {{ colors.isSelected }}
                </b-col>
              </b-row>

              <b-button
                size="sm"
                variant="outline-secondary"
                @click="row.toggleDetails"
              >
                Hide Details
              </b-button>
            </div>
          </template>
          <!-- Detail End -->
          <template #cell(ID)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(price)="data">
            &#163;{{ data.value }}
          </template>
          <template #cell(actions)="data">
            <div class="d-flex">
              <router-link :to="'/mobile-tablet/update-part/' + data.item.id">
                <b-button
                  variant="flat-warning"
                  class="btn-icon"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
              </router-link>
              <b-button
                variant="flat-danger"
                class="btn-icon"
                @click="deletePart(data.item)"
              >
                <feather-icon icon="DeleteIcon" />
              </b-button>
            </div>
          </template>
        </b-table>
        <b-card-footer class="d-flex">
          <b-row class="w-100">
            <b-col
              md="6"
            >
              <b-form-group
                label-cols="4"
                label-cols-md="2"
                label="Per Page"
                label-for="perpage"
              >
                <b-form-select
                  id="perpage"
                  v-model="perPage"
                  :options="option"
                  class="w-md-25 w-25"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="6"
            >
              <div class="d-flex justify-content-md-end justify-content-start">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  aria-controls="my-table"
                />
              </div>
            </b-col>
          </b-row>
        </b-card-footer>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const mobileTabletModule = createNamespacedHelpers('mobileTablet')

export default {
  data() {
    return {
      isBusy: false,
      loader: false,
      fields: [
        { key: 'show_details', label: 'Details' },
        { key: 'ID', label: 'ID' },
        { key: 'name', label: 'name' },
        { key: 'admin_name', label: 'admin name' },
        { key: 'price', label: 'price' },
        { key: 'quantity', label: 'quantity' },
        { key: 'time_required', label: 'time_required' },
        { key: 'mobile_models.name', label: 'mobile_models' },
        { key: 'actions', label: 'actions' },
      ],
      items: [],
      // Per Page & Pagination
      perPage: 20,
      currentPage: 1,
      option: [
        { value: 20, text: '20' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
      ],
      totalRows: 1,
      // Sort
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      sortFields: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'quantity', label: 'Quantity', sortable: true },
      ],
      // Filter
      filter: '',
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.sortFields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  async mounted() {
    await this.getParts()
  },
  methods: {
    ...mobileTabletModule.mapActions(['SHOW_ALL_PARTS', 'DELETE_PART']),

    async getParts() {
      try {
        this.isBusy = true
        this.items = await this.SHOW_ALL_PARTS()
        this.totalRows = this.items.length
        this.isBusy = false
      } catch (error) {
        this.isBusy = false
      }
    },

    async deletePart(item) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete this part', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if (value) {
            try {
              this.loader = true
              const resp = await this.DELETE_PART(item.id)
              this.loader = false
              if (resp) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Part deleted!',
                    icon: 'PlusIcon',
                    variant: 'success',
                    text: 'Part has been deleted successfully!',
                  },
                })
                await this.getParts()
              }
            } catch (error) {
              this.items = []
              this.totalRows = 1
              this.loader = false
            }
          }
        })
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
